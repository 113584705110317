import { cr, ap, rp } from '@/lib/ui/dom';
import getUserProfile from '@/util/getUserProfile';
import i from '@/lib/ui/i';
import { translate as t } from '@/util/i18n';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import button from '@/lib/ui/button';
import textInput from '@/lib/ui/textInput';
import confirmDialog from '@/components/dialog/confirmDialog';
import phoneInput from '@/lib/ui/phoneInput';

const sosManager = () => {
  const sosTableBody = cr('tbody', 'sos-list');
  const sosTable = ap(
    cr('table', 'c-table'),
    ap(cr('thead'), ap(cr('tr'), cr('th', null, t('name')), cr('th', null, t('phone')))),
    sosTableBody,
  );
  const addItemWrapper = cr();

  const save = (sosItems, newItem) => {
    if (newItem) {
      sosItems = [...sosItems, newItem];
    }

    api
      .updateMySos(sosItems)
      .then(() => reloadSOSList(true))
      .catch((res) => errorMessage(t('sosListUpdateError'), t('sosListUpdateErrorMsg'), res));
  };

  const remove = (sosItem, sosItems) => {
    confirmDialog(
      t('sosItemRemove'),
      t('sosItemRemoveMsg', null, {
        name: sosItem.key,
      }),
      t('cancel'),
      t('remove'),
    )
      .open()
      .then((confirm) => {
        if (confirm) {
          const idx = sosItems.indexOf(sosItem);
          if (idx >= 0) {
            sosItems.splice(idx, 1);
          }
          save(sosItems);
        }
      });
  };

  const edit = (sosItem, sosItems, newItem) => {
    const onChange = (event) => (sosItem[event.target.name] = event.target.value);

    const editRow = ap(
      cr('tr'),
      ap(
        cr('td'),
        textInput(
          {
            labelText: t('name'),
            onChange,
          },
          {
            name: 'key',
            value: sosItem.key || '',
          },
        ).element,
      ),

      ap(
        cr('td'),
        phoneInput(
          {
            labelText: t('phone'),
            onChange,
          },
          {
            name: 'value',
            value: sosItem.value || '',
          },
        ).element,
      ),

      ap(
        cr('td', 'c-table-row-actions'),
        button(() => save(sosItems, newItem ? sosItem : false), 'type-secondary circle', i('save')),
        button(reloadSOSList, 'type-secondary circle', i('times')),
      ),
    );

    return editRow;
  };

  const createSosItem = (sosItem, sosItems) => {
    const row = ap(
      cr('tr'),
      cr('td', null, sosItem.key),
      cr('td', null, sosItem.value),
      ap(
        cr('td', 'c-table-row-actions'),
        button(() => rp(row, edit(sosItem, sosItems)), 'type-secondary circle', i('pen')),
        button(() => remove(sosItem, sosItems), 'type-secondary circle', i('trash')),
      ),
    );

    return row;
  };

  const createAddRow = (sosItems) => {
    const row = cr('tr');
    const onClick = () => {
      rp(row, edit({}, sosItems, true));
    };

    return ap(
      row,
      ap(cr('td'), ap(button(onClick, null), i('plus', 'inline'), ' ', t('sosItemAdd'))),
    );
  };

  const reloadSOSList = (forceRefresh) =>
    getUserProfile(forceRefresh).then((profile) => {
      const sosItems = profile?.user?.sos?.list ?? [];

      sosTableBody.innerHTML = '';

      ap(
        sosTableBody,
        sosItems.map((sosItem) => createSosItem(sosItem, sosItems)),
        createAddRow(sosItems),
      );
    });

  reloadSOSList();

  return ap(
    cr('div', 'c-sos-manager'),
    ap(cr('h2', 'u-typ-subheading'), i('ambulance', 'u-color-blue-dark'), ' ', t('sosList')),
    cr('p', null, t('sosListText')),
    sosTable,
    addItemWrapper,
  );
};

export default sosManager;
