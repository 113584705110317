import input from './input';
import { on, ap, cr, ac, dc } from './dom';
import is from '../util/is';

/**
 * @typedef TextInputOptions
 * @property {string} [labelText]
 * @property {string} [helperText]
 * @property {Function} [onChange]
 */

/**
 *
 * @param {TextInputOptions} [opts]
 * @param {NamedNodeMap} [attrs]
 * @param {} [siblings]
 */
const textInput = (opts, attrs, siblings) => {
  if (!is.defined(opts) || !is.object(opts)) {
    opts = {};
  }

  const wrapper = input(
    'input',
    Object.assign(opts, {
      className: 'c-textinput' + (opts.className ? ' ' + opts.className : ''),
    }),
    Object.assign(
      {
        type: 'text',
      },
      attrs,
    ),
    null,
    siblings,
  );

  if (is.number(opts.max)) {
    const inputElement = wrapper.inputElement;

    const count = cr('span', null, inputElement.value.length);

    const counter = ap(cr('span', 'input-counter'), count, '/', `${opts.max}`);

    on(inputElement, 'focus', () => {
      ac(counter, 'show');
    });

    on(inputElement, 'blur', () => {
      dc(counter, 'show');
    });

    inputElement.parentElement.insertBefore(counter, inputElement);

    on(inputElement, 'input', (event) => {
      const target = event.target;

      if (target.value.length > opts.max) {
        ac(counter, 'overflow');
      } else {
        dc(counter, 'overflow');
      }

      count.innerHTML = target.value.length;

      opts.onChange(event);
    });
  }

  return wrapper;
};

export default textInput;
