<template>
  <div>
    <v-row>
      <v-container fluid class="u-bg-blue-primary pa-sm-6 pa-lg-16">
        <v-row class="d-flex justify-center">
          <div class="c-profile">
            <div class="d-flex c-async c-profile-picture">
              <v-img :src="user.profilePhoto">
                <div
                  class="d-flex align-self-center justify-center edit-icon"
                  @click="uploadProfilePhoto"
                >
                  <v-icon size="40" color="white">fa-camera</v-icon>
                </div>
              </v-img>
            </div>
          </div>
        </v-row>

        <v-col v-if="user.managed" class="my-4">
          <v-row class="d-flex justify-center u-color-white">
            <v-icon color="white" size="16">fa-id-badge</v-icon>
            <div class="pl-1">{{ $t('managed') }}</div>
          </v-row>
          <v-row class="d-flex justify-center u-color-white mt-4">{{ $t('managedMeInfo') }}</v-row>
        </v-col>

        <v-row class="d-flex justify-center mt-2 ml-2 pl-6">
          <div class="u-color-white u-typ-title">{{ user.name }}</div>
          <v-btn
            variant="outlined"
            size="x-small"
            icon
            color="white"
            class="ml-2"
            :disabled="user.managed"
            @click="editUserName"
          >
            <v-icon>fa-pen</v-icon>
          </v-btn>
          <v-dialog v-model="editUserNameDialog" max-width="500px" eager>
            <v-card>
              <v-card-title>{{ $t('editUsername') }}</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="editUserNameForm.name"
                  :rules="nameRules"
                  :counter="100"
                  :label="$t('teamName')"
                  prepend-icon="fa-tag"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="cancelEditUserName">{{ $t('cancel') }}</v-btn>
                <v-btn color="primary" @click="saveUserName">{{ $t('save') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

        <v-col class="my-6">
          <v-row class="d-flex justify-center u-color-white">{{ user.email }}</v-row>
          <v-row class="d-flex justify-center u-color-white">{{ user.mobile }}</v-row>
        </v-col>
      </v-container>
    </v-row>
    <div class="o-wrapper c-level">
      <v-row>
        <TfaManager class="my-6" />
      </v-row>
      <v-row>
        <SosManager class="my-6" />
      </v-row>
      <v-row class="my-6">
        <v-container class="appended-manager">
          <v-row class="d-flex align-baseline justify-left mb-2">
            <v-icon color="blueDark" class="u-color-blue-dark">fa-lock</v-icon>
            <div class="u-typ-subheading ml-2">{{ $t('changePassword') }}</div>
          </v-row>
          <v-row>
            <div>{{ $t('changePasswordText') }}</div>
          </v-row>
          <v-row>
            <v-btn class="my-6 py-6" outlined primary large color="primary" @click="sendResetLink">
              {{ $t('sendResetLink') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from '@/util/api.js';
import snackbar from '@/lib/ui/snackbar';
import { nameRules } from '@/consts/userRules';
import getUserProfile from '@/util/getUserProfile';
import errorMessage from '@/util/errorMessage';
import fileInput from '@/util/fileInput';
import fileAjax from '@/util/fileAjax';
import { BlockBlobClient } from '@azure/storage-blob';
import TfaManager from './TfaManager.vue';
import SosManager from './SosManager.vue';

export default {
  name: 'Profile',

  components: {
    SosManager,
    TfaManager,
  },

  data: () => ({
    editUserNameDialog: null,
    editUserNameForm: {
      name: '',
    },
    user: {},
    customer: {},
    customers: [],
    devices: [],
    features: [],
    pendingInvites: [],
    units: [],

    nameRules: nameRules(),
  }),

  created() {
    this.loadUserProfile();
  },

  methods: {
    async loadUserProfile() {
      getUserProfile().then((profile) => {
        this.user = profile.user;
        this.customer = profile.customer;
        this.customers = profile.customers;
        this.devices = profile.devices;
        this.features = profile.features;
        this.pendingInvites = profile.pendingInvites;
        this.units = profile.units;
      });
    },

    uploadProfilePhoto() {
      fileInput('image/jpeg, image/png, image/gif', false).then((imageFile) => {
        api.getProfilePhotoUploadLink().then((res) => {
          const presignedUrl = res.presignedUrl;

          let task;

          if (presignedUrl.indexOf('blob.core.windows.net') < 0) {
            task = fileAjax({
              url: presignedUrl,
              file: imageFile,
              type: 'PUT',
            });
          } else {
            const blobClient = new BlockBlobClient(presignedUrl);

            task = blobClient.uploadData(imageFile);
          }

          task
            .then(() => {
              location.reload();
            })
            .catch((err) => {
              errorMessage(
                self.$t('uploadProfilePictureError'),
                self.$t('uploadProfilePictureErrorMsg'),
                err,
              );
            });
        });
      });
    },

    editUserName() {
      this.editUserNameForm.name = this.user.name;
      this.editUserNameDialog = true;
    },

    cancelEditUserName() {
      this.editUserNameDialog = false;
    },

    async saveUserName() {
      try {
        await api.updateUsername(this.editUserNameForm.name).then(() => {
          this.user.name = this.editUserNameForm.name;
          this.cancelEditUserName();
        });
      } catch (error) {
        errorMessage(this.$t('updateUsernameError'), this.$t('updateUsernameErrorMsg'), error);
      }
    },

    async sendResetLink() {
      try {
        await api.startResetPassword(this.user.email).then(() => {
          snackbar(this.$t('passwordResetEmailSent')).open();
        });
      } catch (error) {
        errorMessage(this.$t('sendResetLinkError'), this.$t('sendResetLinkErrorMsg'), error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
