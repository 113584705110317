<template>
  <div />
</template>

<script>
import tfaManager from './tfaManager.js';
export default {
  name: 'TfaMangaer',

  mounted() {
    this.$el.append(tfaManager());
  },
};
</script>
