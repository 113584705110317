import is from '../lib/util/is';

const DEFAULT_OPTIONS = {
  url: false,
  file: false,
  type: 'GET',
  headers: {
    'Content-Type': 'binary/octet-stream',
  },

  timeout: 10000,
};

/**
 * Send a file over ajax.
 *
 * @param  {object} opts            options
 * @param  {string} opts.url        the url to call (required)
 * @param  {string} opts.type       the HTTP type, e.g. 'GET' (default: 'POST')
 * @param  {File}   opts.file       the file to send (default: false)
 * @param  {object} opts.headers    a map of headers to add to the request
 *
 * @return {Promise}                a Promise
 */
const fileAjax = (userOpts) => {
  const opts = {
    ...DEFAULT_OPTIONS,
    headers: {
      ...DEFAULT_OPTIONS.headers,
      ...(userOpts && userOpts.headers),
    },
    ...userOpts,
  };

  if (!opts.url) {
    return;
  }

  const r = new XMLHttpRequest();

  r.timeout = DEFAULT_OPTIONS.timeout;
  if (is.number(opts.timeout) && opts.timeout > 0) {
    r.timeout = opts.timeout;
  }

  const url = opts.url;

  r.open(opts.type, url, true);

  Object.keys(opts.headers).forEach((header) => {
    r.setRequestHeader(header, opts.headers[header]);
  });

  return new Promise((resolve, reject) => {
    const handleResponse = (success) => {
      if (success) {
        resolve({
          status: r.status,
        });
      } else {
        reject({
          status: r.status,
        });
      }
    };

    r.onreadystatechange = () => {
      if (r.readyState === XMLHttpRequest.DONE) {
        if (r.status >= 200 && r.status < 300) {
          handleResponse(true);
        } else {
          handleResponse(false);
        }
      }
    };

    if (opts.file) {
      r.send(opts.file);
    } else {
      r.send();
    }
  });
};

export default fileAjax;
