import { ap, cr, ac, dc } from './dom';
import i from './i';

/**
 * Creates a validation label
 */

const validationLabel = (labelContent, className, iconName) => {
  const lbl = cr('label', 'c-validation-label ', labelContent);
  const element = ap(
    cr('div', 'c-validation-error u-hidden'),
    lbl,
    i(iconName ? iconName : 'exclamation-triangle', 'c-validation-icon'),
  );

  return {
    element,
    show: (errorMsg) => {
      dc(element, 'u-hidden');
      lbl.innerHTML = errorMsg ? errorMsg : labelContent;
    },
    hide: () => ac(element, 'u-hidden'),
  };
};

export default validationLabel;
