import input from './input';

/**
 * @typedef NumberInputOptions
 * @property {string} [labelText]
 * @property {string} [helperText]
 * @property {Function} [onChange]
 */

/**
 *
 * @param {NumberInputOptions} [opts]
 * @param {NamedNodeMap} [attrs]
 * @param {} [siblings]
 */
const numberInput = (opts, attrs, children) =>
  input(
    'input',
    Object.assign({}, opts, {
      onChange: (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        e.target.value = value;
        opts.onChange(e);
      },
    }),
    Object.assign(
      {
        pattern: '\\d*',
      },
      attrs,
    ),
    children,
  );

export default numberInput;
