import { cr, ap } from '@/lib/ui/dom';
import getUserProfile from '@/util/getUserProfile';
import i from '@/lib/ui/i';
import { translate as t } from '@/util/i18n';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import button from '@/lib/ui/button';
import safeGet from '@/lib/util/safeGet';
import { TFA_OFF } from '@/consts/tfaStates';
import QRCode from 'qrcode';
import confirmDialog from '@/components/dialog/confirmDialog';
import form from '@/lib/ui/form';
import numberInput from '@/lib/ui/numberInput';

const ISSUER = 'TeamAlert';

const getQrUrl = (userEmail, secret) =>
  `otpauth://totp/${ISSUER}:${userEmail}?secret=${secret}&issuer=${ISSUER}`;

const generateQRCode = (userEmail, secret) => {
  const qrCodeCanvas = cr('canvas', 'qr-code');

  const url = getQrUrl(userEmail, secret);

  QRCode.toCanvas(qrCodeCanvas, url);

  return qrCodeCanvas;
};

const tfaManager = () => {
  const tfaContent = cr();

  const showQRCode = (userId, userEmail, wrapper) => {
    const qrWrapper = cr('div', 'qr-wrapper');

    api
      .generateTimeSecret()
      .then((res) => {
        let code = '';

        const cancel = () => {
          createTFADisabledContent(userId, userEmail);
        };

        const verifyCode = () => {
          api
            .validateTotpCode(code)
            .then((res) => {
              if (res.valid === true) {
                ap(qrWrapper, ap(cr('p', 'status'), i('check-circle'), t('codeIsValid')));

                api
                  .updateTFAMethod('totp')
                  .then(() => {
                    ap(qrWrapper, ap(cr('p', 'status'), i('check-circle'), t('tfaEnabled')));

                    setTimeout(() => {
                      reloadTFAMethod();
                    }, 3000);
                  })
                  .catch((res) =>
                    errorMessage(t('updateTFAMethodError'), t('updateTFAMethodErrorMsg'), res),
                  );
              } else {
                codeInput.setValidity(false);
              }
            })
            .catch(() => {
              codeInput.setValidity(false);
            });
        };

        wrapper.innerHTML = '';

        const codeInput = numberInput(
          {
            labelText: t('code'),
            validation: t('incorrectCode'),
            className: 'code-input',
            onChange: (e) => {
              code = e.target.value;
              codeInput.setValidity(true);
            },
          },
          {
            autocomplete: 'off',
          },
        );

        ap(
          wrapper,
          ap(
            qrWrapper,
            generateQRCode(userEmail, res.secret),
            ap(
              cr('div', 'form'),
              cr(
                'p',
                null,
                t('tfaScanQR', null, {
                  gaLink: 'https://support.google.com/accounts/answer/1066447',
                  aLink: 'https://authy.com/download/',
                }),
              ),

              ap(
                form(verifyCode),
                codeInput.element,
                ap(
                  cr(),
                  button(cancel, 'type-secondary', t('cancel')),
                  button(verifyCode, null, t('verify'), {
                    type: 'submit',
                  }),
                ),
              ),
            ),
          ),
        );

        codeInput.inputElement.focus();
      })
      .catch((res) =>
        errorMessage(t('generateTimeSecretError'), t('generateTimeSecretErrorMsg'), res),
      );
  };

  const createTFADisabledContent = (userId, userEmail) => {
    tfaContent.innerHTML = '';
    const wrapper = cr();

    const enable = () => {
      showQRCode(userId, userEmail, wrapper);
    };

    ap(tfaContent, ap(wrapper, cr('p', null, t('tfaDisabled')), button(enable, null, t('enable'))));
  };

  const createTFAEnabledContent = () => {
    tfaContent.innerHTML = '';

    const disable = () => {
      confirmDialog(t('tfaDisable'), t('tfaDisableMsg'), t('cancel'), t('disable'))
        .open()
        .then((confirm) => {
          if (confirm) {
            api.updateTFAMethod(TFA_OFF).then(() => {
              reloadTFAMethod();
            });
          }
        });
    };

    ap(tfaContent, cr('p', null, t('tfaEnabled')), button(disable, 'type-secondary', t('disable')));
  };

  const reloadTFAMethod = () => {
    getUserProfile(true)
      .then((profile) => {
        const userId = safeGet(profile, 'user', 'userId');
        const userEmail = safeGet(profile, 'user', 'email');
        const tfaMode = safeGet(profile, 'user', 'use2FA');
        if (!tfaMode || tfaMode === TFA_OFF) {
          createTFADisabledContent(userId, userEmail);
        } else {
          createTFAEnabledContent();
        }
      })
      .catch((res) => errorMessage(t('getUserProfileError'), t('getUserProfileErrorMsg'), res));
  };

  reloadTFAMethod();

  return ap(
    cr('div', 'c-tfa-manager'),
    ap(cr('h2', 'u-typ-subheading'), i('key', 'u-color-blue-dark'), ' ', t('tfa')),
    tfaContent,
  );
};

export default tfaManager;
