import { ap, cr, on, rm, st } from './dom';
import is from './../util/is';
import button from './button';

const defaultDuration = 3000;

/**
 * Displays a snackbar at the bottom of the screen
 *
 * @param {string} text the text to display
 * @param {object} [closeAction] an action to add to the snackbar that also closes the snackbar
 * @param {string} closeAction.text the action text to display
 * @param {function} closeAction.cb the callback to call when the action is clicked
 * @param {number|boolean} [durationInMs] a duration to display the toast in ms, or false if persistent
 * @param {string} [type] the snackbar type. One of 'warning' or 'error'
 */
const snackbar = (text, closeAction, durationInMs, type) => {
  let innerClose;

  if (type) {
    type = `type-${type}`;
  }

  const open = () => {
    const snack = cr('div', 'c-snackbar ' + type || '');

    const fadeIn = () => {
      st(snack, {
        transition: 'none',
        maxHeight: null,
      });
      const height = snack.clientHeight;

      st(snack, {
        transition: 'none',
        maxHeight: 0,
      });
      setTimeout(() => {
        st(snack, {
          transition: null,
          maxHeight: height + 'px',
          opacity: 1,
        });
      }, 100);
    };

    const fadeOut = () => {
      st(snack, {
        maxHeight: 0,
        opacity: 0,
      });
    };

    innerClose = () => {
      fadeOut();
      on(snack, 'transitionend', () => {
        rm(snack);
      });
    };

    const actionClick = (event) => {
      closeAction.cb(event);
      innerClose();
    };

    ap(
      snack,
      cr('p', 'c-snackbar-text', text),
      closeAction ? button(actionClick, 'c-snackbar-action', closeAction.text) : null,
    );

    ap(document.body, snack);
    setTimeout(fadeIn, 100);

    if (durationInMs !== false) {
      if (!is.number(durationInMs)) {
        durationInMs = defaultDuration;
      }

      setTimeout(() => {
        innerClose();
      }, durationInMs);
    }
  };

  const close = () => {
    if (is.function(innerClose)) {
      innerClose();
    }
  };

  return {
    /**
     * Opens the snackbar
     */
    open,

    /**
     * Closes the snackbar
     */
    close,
  };
};

export default snackbar;
