import { ap, cr, ac, dc, on } from './dom';
import is from './../util/is';
import validationLabel from './validationLabel';
import button from './button';
import i from './i';

const input = (tag, opts, attrs, children, siblings) => {
  let counter = 1;

  if (!is.defined(tag)) {
    tag = 'input';
  }

  if (!is.defined(attrs)) {
    attrs = {};
  }

  if (!is.string(attrs.name)) {
    attrs.name = 'unnamed-input-' + counter++;
  }

  attrs.id = attrs.id || attrs.name;

  let placeholder;
  if (attrs.placeholder) {
    placeholder = attrs.placeholder;
    delete attrs.placeholder;
  }

  const inputClassName = opts.inputClassName ? ' ' + opts.inputClassName : '';

  const inputElement = cr(tag, 'u-typ-body-1' + inputClassName, null, attrs);

  const label = ap(cr('label', 'hiding', null, { for: attrs.name }), opts.labelText);

  if (is.defined(attrs.value)) {
    ac(label, 'make-space');
  }

  on(label, 'click', () => {
    inputElement.placeholder = placeholder || '';
    inputElement.focus();
  });

  if (is.function(opts.onChange)) {
    if (attrs.type === 'checkbox') {
      on(inputElement, 'change', opts.onChange);
    } else {
      on(inputElement, 'input', opts.onChange);
    }
  }
  if (is.function(opts.onBlur)) {
    on(inputElement, 'blur', opts.onBlur);
  }

  on(inputElement, 'focus', () => {
    inputElement.placeholder = placeholder || '';
    ac(label, 'focus');
    ac(label, 'make-space');
  });

  on(inputElement, 'blur', () => {
    inputElement.placeholder = '';
    dc(label, 'focus');
    if (!inputElement.value) {
      dc(label, 'make-space');
    }
  });

  const className = opts.className ? ' ' + opts.className : '';

  const inputLine = cr('div', 'c-input-line');

  let mValidationLabel = null;
  if (opts.validation) {
    mValidationLabel = validationLabel(opts.validation);
  }

  const setValue = (value) => {
    if (attrs.type === 'checkbox') {
      inputElement.checked = attrs.checked = value;
    } else {
      inputElement.value = attrs.value = value;
    }
    if (is.strNotEmpty(value)) {
      ac(label, 'make-space');
    }
  };

  const onClickClear = () => {
    setValue('');
    if (is.function(opts.onChange)) {
      opts.onChange({
        target: {
          name: attrs.name,
          value: '',
        },
      });
    }
  };

  const clearButton = opts.clearButton
    ? ap(button(onClickClear, 'c-clear-button', i('times')))
    : null;

  const helperText = opts.helperText ? cr('p', 'helper-text', opts.helperText) : null;

  const setHelperText = (newText) => {
    if (helperText) {
      helperText.textContent = newText;
    }
  };

  const element = ap(
    cr('fieldset', 'c-input' + className),
    label,
    ap(inputElement, children),
    clearButton,
    inputLine,
    helperText,
    mValidationLabel && mValidationLabel.element,
    siblings,
  );

  const setValidity = (valid, validationMessage) => {
    if (valid) {
      dc(element, 'invalid');
      if (mValidationLabel) {
        if (helperText) {
          dc(helperText, 'u-hidden');
        }
        mValidationLabel.hide();
      }
    } else {
      ac(element, 'invalid');
      if (mValidationLabel) {
        if (helperText) {
          ac(helperText, 'u-hidden');
        }
        mValidationLabel.show(validationMessage);
      }
    }
  };

  // Fix for autofill events. The change event is triggered in Firefox, but
  // webkit browsers need custom CSS trigger
  on(inputElement, ['change', 'animationstart'], (e) => {
    if (is.strNotEmpty(inputElement.value) || e.animationName === 'autofill') {
      ac(label, 'make-space');
    }
  });

  return {
    element,
    label,
    inputElement,
    inputLine,
    setHelperText,
    setValue,
    setValidity,
  };
};

export default input;
