import { cr, on } from './dom';
import is from './../util/is';

/**
 * Creates a form that prevents page reload on submit or invalid.
 *
 * @param {Function} [onSubmit] a callback for when the form is submitted or
 *                              invalidated
 * @param {string} [className] the className of the form
 * @param {object} [attrs] the attributes of the form element
 *
 * @returns {HTMLFormElement} a form element
 */
const form = (onSubmit, className, attrs) => {
  const formElement = cr('form', className, null, attrs);

  on(formElement, ['submit', 'invalid'], (e) => {
    e.preventDefault();
    if (is.function(onSubmit)) {
      onSubmit(e);
    }
  });

  return formElement;
};

export default form;
