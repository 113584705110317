import { ap, cr, st } from './dom';
import textInput from './textInput';
import flag from './flag';
import countries from './countryPhoneNumbers.json';
import advancedSelect from './advancedSelect';
import button from './button';
import safeGet from '../util/safeGet';
import messageDialog from '@/components/dialog/messageDialog';
import api from '../../util/api';
import is from '../util/is';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

let savedCountryFromIP = null;

const phoneInput = (opts, attrs, siblings) => {
  if (!is.defined(opts) || !is.object(opts)) {
    opts = {};
  }

  if (!is.defined(attrs) || !is.object(attrs)) {
    attrs = {};
  }

  let selectedCountry;
  let number = '';

  if (attrs.value) {
    // Start by checking predefined value
    const data = parsePhoneNumberFromString(attrs.value);
    if (data) {
      selectedCountry = countries.find((country) => country.phone === data.countryCallingCode);
      number = data.nationalNumber;
    }
  }

  if (!selectedCountry) {
    if (savedCountryFromIP) {
      // If no value was set, and there is a saved country, use it
      selectedCountry = savedCountryFromIP;
    } else {
      // Fall back to US if no country was found
      selectedCountry = countries.find((country) => country.id.toLowerCase() === 'us');
    }
  }

  const getCountryFromIP = (updateSelected = true) =>
    api
      .getGeoAddress()
      .then((res) => {
        const countryFromIP = res.country.toLowerCase();
        const country = countries.find((country) => country.id.toLowerCase() === countryFromIP);
        if (country) {
          savedCountryFromIP = country;
          if (updateSelected) {
            updateCountryCode(country);
          }
        }
      })
      .catch(() => {
        // Do nothing
      });

  // Load country from IP if none has been loaded since last render
  if (!savedCountryFromIP) {
    getCountryFromIP(false);
  }

  const formatNumber = (number) => {
    let formattedNumber = '';
    for (let i = 0; i < number.length; i++) {
      if ([3, 5, 8, 10, 13, 15, 18].indexOf(i) >= 0) {
        formattedNumber += ' ';
      }
      formattedNumber += number[i];
    }
    return formattedNumber;
  };

  const callOnChange = () => {
    opts.onChange({
      target: {
        name: attrs.name,
        value: '+' + selectedCountry.phone + number,
      },
    });
  };

  const onChangeNumber = (e) => {
    number = e.target.value.replace(/[^0-9]/g, '');
    phoneNumberInput.setValue(formatNumber(number));
    number = number.replace(/\s/g, '');
    callOnChange();
  };

  const updateInputPadding = () => {
    setTimeout(() => {
      const ccWidth = selectedCountryCode.getBoundingClientRect().width;
      const extraPadding = 8;
      const totalPadding = ccWidth + extraPadding;
      st(phoneNumberInput.inputElement, {
        paddingLeft: totalPadding + 'px',
      });
    }, 100);
  };

  const updateCountryCode = (country, doCallOnChange = true) => {
    selectedCountry = country;
    selectedCountryCode.innerHTML = '';
    ap(
      selectedCountryCode,
      cr('label', 'country-code-label', 'Country'),
      flag(country.id.toLowerCase()),
      ' ',
      '+' + country.phone,
    );
    if (doCallOnChange) {
      callOnChange();
    }

    updateInputPadding();
  };

  const selectCountry = (currentCountry) => {
    const countrySelector = advancedSelect(
      {
        options: countries,
        onChange: (e) => {
          const country = e.target.value;
          updateCountryCode(country);
          dialog.close();
        },
        optionRenderer: (option, result) =>
          ap(cr(), flag(option.id.toLowerCase()), ' ', result, ` (+${option.phone})`),
        selectedRenderer: (option) =>
          ap(cr(), flag(option.id.toLowerCase()), ' ', option.name, ` (+${option.phone})`),
        min: 1,
      },
      {
        value: currentCountry,
      },
    );

    const dialog = messageDialog(
      safeGet(opts, 'text', 'countryDialog', 'title') || 'Select Country',
      countrySelector.element,
      safeGet(opts, 'text', 'countryDialog', 'title') || 'Cancel',
    );

    dialog.open();
  };

  const selectedCountryCode = button(() => selectCountry(selectedCountry), 'selected-country-code');

  const phoneNumberInput = textInput(
    Object.assign({}, opts, {
      onChange: onChangeNumber,
      onBlur: () => {
        if (opts.onBlur) {
          opts.onBlur({
            target: {
              name: attrs.name,
              value: '+' + selectedCountry.phone + number,
            },
          });
        }
      },
    }),
    Object.assign({}, attrs, {
      value: number,
    }),
    siblings,
  );

  updateCountryCode(selectedCountry, false);

  const element = ap(
    cr('fieldset', 'c-phone-input'),
    phoneNumberInput.element,
    selectedCountryCode,
  );

  const setValue = (value) => {
    if (!value || value.trim() === '') {
      phoneNumberInput.setValue('');
    } else {
      value = value.replace('+', '');
      const country = countries.find((country) => value.indexOf(country.phone) === 0);
      if (country) {
        number = value.replace(country.phone, '');
        phoneNumberInput.setValue(formatNumber(number));
        updateCountryCode(country);
      }
    }
  };

  const setDisabled = (disabled) => {
    selectedCountryCode.disabled = disabled;
    phoneNumberInput.inputElement.disabled = disabled;
  };

  return {
    element,
    setValidity: phoneNumberInput.setValidity,
    setValue,
    setDisabled,
  };
};

export default phoneInput;
