import { ap, cr, on, ac, dc } from './dom';
import textInput from './textInput';
import is from './../util/is';
import button from './button';
import i from './i';

const resultClass = 'result';

const advancedSelect = (opts, attrs) => {
  if (!is.object(opts)) {
    opts = {};
  }

  if (!is.object(attrs)) {
    attrs = {};
  }

  attrs.autocomplete = 'off';

  if (!is.defined(attrs.value)) {
    attrs.value = [];
  } else if (!is.array(attrs.value)) {
    attrs.value = [attrs.value];
  }
  const preselected = attrs.value;

  if (!is.array(opts.options)) {
    opts.options = [];
  }

  const classNames = [
    'c-input',
    'c-advanced-select',
    opts.multiple ? 'multi-select' : '',
    opts.className ? opts.className : '',
  ];

  const element = cr('fieldset', classNames.join(' '));

  const optionsMap = {};

  const selectedMap = {};
  const selected = cr('ul', 'c-advanced-select-selected');

  const updateSelected = (callOnChange) => {
    selected.innerHTML = '';
    const selectedOptions = Object.keys(selectedMap)
      .filter((optionId) => selectedMap[optionId])
      .map((selectedId) => optionsMap[selectedId]);

    if (callOnChange !== false && is.function(opts.onChange)) {
      opts.onChange({
        target: {
          name: attrs.name,
          value: opts.multiple ? selectedOptions : selectedOptions[0],
          type: 'advancedSelect',
        },
      });
    }

    if (!opts.multiple) {
      if (selectedOptions.length > 0) {
        ac(searchFilter.inputElement, 'u-hidden');
        ac(searchFilter.label, 'make-space');
      } else {
        dc(searchFilter.inputElement, 'u-hidden');
        dc(searchFilter.label, 'make-space');
      }
    }

    ap(
      selected,
      selectedOptions.map((selectedOption) => {
        const listItem = ap(
          cr('li', 'c-advanced-select-selection'),
          is.function(opts.selectedRenderer)
            ? opts.selectedRenderer(selectedOption)
            : selectedOption.name,
        );

        if (opts.multiple || !is.defined(opts.min)) {
          ap(
            listItem,
            ap(
              button((event) => {
                event.stopPropagation();
                selectedMap[selectedOption.id] = false;
                dc(searchFilter.element, 'u-hidden');
                updateSelected();
              }, 'c-advanced-select-remove-selected-button'),
              i('times'),
            ),
          );
        }

        if (!opts.multiple) {
          on(listItem, 'click', (event) => {
            event.stopPropagation();
            dc(filterResults, 'u-hidden');
            dc(searchFilter.inputElement, 'u-hidden');
            dc(searchFilter.label, 'make-space');
            dc(searchFilter.inputLine, 'hide');
            searchFilter.inputElement.focus();
          });
        }

        return listItem;
      }),
    );
  };

  const filterResults = cr('ul', 'c-advanced-select-filter-results');
  ac(filterResults, 'u-hidden');

  const selectOption = (id) => {
    if (!opts.multiple) {
      Object.keys(selectedMap).forEach((key) => (selectedMap[key] = false));
    }
    selectedMap[id] = true;
    ac(filterResults, 'u-hidden');
    updateSelected();
  };

  const updateResults = (search) => {
    const resultItems = opts.options
      .filter(
        (option) =>
          option.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 && !selectedMap[option.id],
      )
      .slice(0, opts.displayMaxResults || undefined)
      .map((option) => {
        const name = option.name;
        const idxStart = name.toLowerCase().indexOf(search.toLowerCase());
        const idxEnd = idxStart + search.length;

        const result = ap(
          cr('span'),
          cr('span', null, name.substring(0, idxStart)),
          cr('span', 'result-match', name.substring(idxStart, idxEnd)),
          cr('span', null, name.substring(idxEnd, name.length)),
        );

        const listItem = ap(
          cr('li', resultClass, null, {
            tabIndex: 0,
            optionId: option.id,
          }),
          is.function(opts.optionRenderer) ? opts.optionRenderer(option, result) : result,
        );

        on(listItem, 'click', () => {
          selectOption(option.id);
          searchFilter.inputElement.value = '';
        });

        return listItem;
      });

    filterResults.innerHTML = '';
    if (resultItems.length > 0) {
      ap(filterResults, resultItems);
    } else {
      ap(filterResults, cr('li', 'no-results', `- ${opts.noResultsTxt || 'No results'} -`));
    }
  };

  const onFilterInput = (event) => {
    const value = event.target.value;
    updateResults(value);
    if (filterResults.innerHTML !== '') {
      dc(filterResults, 'u-hidden');
    }
  };

  const searchFilter = textInput(
    {
      labelText: opts.labelText,
      helperText: opts.helperText,
      onChange: onFilterInput,
      validation: opts.validation,
    },
    {
      name: 'filter',
      autocomplete: 'off',
    },
  );

  on([searchFilter.inputElement, filterResults], 'click', (e) => e.stopPropagation());

  on(searchFilter.label, 'click', (event) => {
    event.stopPropagation();
    if (opts.multiple) {
      updateResults('');
      if (filterResults.innerHTML !== '') {
        dc(filterResults, 'u-hidden');
      }
    }
  });

  on(searchFilter.inputElement, ['focus', 'click'], () => {
    updateResults('');
    if (filterResults.innerHTML !== '') {
      dc(filterResults, 'u-hidden');
    }
  });

  const hideOptions = () => {
    ac(filterResults, 'u-hidden');
    if (!opts.multiple) {
      const selectedOptions = Object.keys(selectedMap)
        .filter((optionId) => selectedMap[optionId])
        .map((selectedId) => optionsMap[selectedId]);
      if (selectedOptions.length > 0) {
        ac(searchFilter.inputElement, 'u-hidden');
        ac(searchFilter.label, 'make-space');
      }
    }
  };

  on(searchFilter.inputElement, 'blur', (event) => {
    const newFocus = event.relatedTarget;
    if (!newFocus || newFocus.className.indexOf(resultClass) < 0) {
      hideOptions();
    }
  });

  on(window, 'click', () => {
    hideOptions();
  });

  on(element, 'keydown', (event) => {
    const activeElement = document.activeElement;

    const searchIsActive = activeElement === searchFilter.inputElement;
    const optionIsActive = activeElement.className.indexOf(resultClass) >= 0;

    if (searchIsActive || optionIsActive) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          if (searchIsActive) {
            filterResults.firstChild.focus();
          } else if (activeElement.nextElementSibling) {
            activeElement.nextElementSibling.focus();
          } else {
            filterResults.lastChild.focus();
          }
          return true;
        case 'ArrowUp':
          event.preventDefault();
          if (searchIsActive) {
            filterResults.lastChild.focus();
          } else if (activeElement.previousElementSibling) {
            activeElement.previousElementSibling.focus();
          } else {
            filterResults.firstChild.focus();
          }
          return true;
        case 'Enter':
          if (optionIsActive) {
            const id = activeElement.optionId;
            selectOption(id);
          }
          return true;
        case 'Escape':
          hideOptions();
          return true;
        default:
          return false;
      }
    }
  });

  if (opts.multiple) {
    ap(
      element,
      ap(cr('div', 'c-advanced-select-add'), searchFilter.element, filterResults),
      selected,
    );
  } else {
    searchFilter.element.insertBefore(filterResults, searchFilter.inputLine);
    searchFilter.element.insertBefore(selected, searchFilter.inputLine);
    ap(element, ap(cr('div', 'c-advanced-select-add'), searchFilter.element));
  }

  const updateOptions = (newOptions) => {
    opts.options = newOptions;
    Object.keys(optionsMap).forEach((optionKey) => delete optionsMap[optionKey]);
    newOptions.forEach((option) => (optionsMap[option.id] = option));
    updateResults(searchFilter.inputElement.value);
  };

  updateOptions(opts.options);

  const clear = (callOnChange) => {
    Object.keys(selectedMap).forEach((key) => (selectedMap[key] = false));
    dc(searchFilter.element, 'u-hidden');
    updateSelected(callOnChange);
  };

  if (is.array(preselected)) {
    preselected.forEach((selection) => {
      const id = is.object(selection) ? selection.id : selection;
      selectedMap[id] = true;
    });
    ac(filterResults, 'u-hidden');
    updateSelected(false);
  }

  const toggleSelect = (id) => {
    if (!opts.multiple) {
      Object.keys(selectedMap).forEach((key) => (selectedMap[key] = false));
    }
    const selected = (selectedMap[id] = !selectedMap[id]);
    ac(filterResults, 'u-hidden');
    updateSelected();
    return selected;
  };

  const setSelected = (id) => {
    if (!opts.multiple) {
      Object.keys(selectedMap).forEach((key) => (selectedMap[key] = false));
    }
    selectedMap[id] = true;
    updateSelected();
  };

  const setValue = (ids) => {
    if (!opts.multiple) {
      Object.keys(selectedMap).forEach((key) => (selectedMap[key] = false));
    }

    ids.forEach((id) => (selectedMap[id] = true));
    updateSelected();
  };

  return {
    element,
    searchFilter,
    toggleSelect,
    setSelected,
    setValue,
    updateOptions,
    clear,
    setValidity: searchFilter.setValidity,
  };
};

export default advancedSelect;
