<template>
  <div />
</template>

<script>
import smsManager from './sosManager.js';
export default {
  name: 'SosMangaer',

  mounted() {
    this.$el.append(smsManager());
  },
};
</script>
